@import 'typography.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 100%;
}

.__grid-debugMode > * {
  @apply bg-debug;
}

select {
  &:focus {
    @apply focus-visible:ring-blue-mediumLight;
  }
}

body {
  @apply font-ttHoves;
}

/* Cta(s) */
.__Npt-Cta {
  @apply rounded-[4px] focus-visible:outline-none cursor-pointer;

  & > * {
    @apply cursor-pointer;
  }
  &-link {
    @apply bg-white text-black border-1 border-transparent hover:border-gray-mediumLight h-[45px] min-w-[178px];
    @apply hover:bg-green-light;
    @apply active:ring-offset-2 active:ring-green-medium active:ring-2;
    @apply focus-visible:ring-2 focus-visible:ring-blue-mediumLight;
    @apply group-active:ring-offset-2 group-active:ring-green-medium group-active:ring-2;
    @apply group-focus-visible:ring-2 group-focus-visible:ring-blue-mediumLight;
  }
  &-link-disabled {
    @apply cursor-not-allowed border-gray-mediumLight text-gray-mediumLight;
    & > * {
      @apply cursor-not-allowed;
    }
  }
  &-dark {
    @apply bg-green text-white h-[45px] min-w-[198px];
    @apply hover:bg-green-medium;
    @apply active:ring-offset-2 active:ring-green-mediumLight active:ring-2;
    @apply focus-visible:ring-blue-mediumLight focus-visible:ring-offset-2 focus-visible:ring-offset-white focus-visible:ring-2;
  }
  &-dark-disabled {
    @apply h-[45px] min-w-[198px] disabled:bg-gray-light disabled:text-gray-medium cursor-not-allowed;
    & > * {
      @apply cursor-not-allowed;
    }
  }
  &-light {
    @apply bg-white text-black border-1 border-gray-mediumLight h-[45px] min-w-[178px];
    @apply hover:bg-green-light;
    @apply active:ring-offset-2 active:ring-green-medium active:ring-2;
    @apply focus-visible:ring-2 focus-visible:ring-blue-mediumLight;
    @apply group-active:ring-offset-2 group-active:ring-green-medium group-active:ring-2;
    @apply group-focus-visible:ring-2 group-focus-visible:ring-blue-mediumLight;
  }
  &-light-disabled {
    @apply h-[45px] min-w-[178px] disabled:border-1 disabled:border-gray-mediumLight disabled:opacity-40 cursor-not-allowed;
    & > * {
      @apply cursor-not-allowed;
    }
  }
}
.__Npt-toggle {
  @apply relative cursor-pointer border-t-gray-medium transition-all rounded-full text-gray-medium border-1 ring-gray-medium right-[1rem];
  @apply relative after:flex after:items-center after:content-[''] after:w-[46px] after:h-[25px] w-[46px] h-[25px] after:bg-gray-mediumLight text-gray-mediumLight after:rounded-[1em] border-0;
  @apply checked:bg-none before:transition-transform before:bottom-1/2 before:right-1/2 before:translate-y-1/2 checked:before:translate-x-[100%] before:absolute before:items-center before:content-[''] before:w-5 before:h-5 before:bg-white before:rounded-full;
  @apply checked:after:bg-red-mediumLight;
  &:before {
    content: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 24 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='21' height='21' rx='10.5' fill='transparent'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.57613 7.57576C7.81044 7.34145 8.19034 7.34145 8.42465 7.57576L11.0004 10.1515L13.5761 7.57576C13.8104 7.34145 14.1903 7.34145 14.4247 7.57576C14.659 7.81007 14.659 8.18997 14.4247 8.42429L11.8489 11L14.4247 13.5758C14.659 13.8101 14.659 14.19 14.4247 14.4243C14.1903 14.6586 13.8104 14.6586 13.5761 14.4243L11.0004 11.8486L8.42465 14.4243C8.19034 14.6586 7.81044 14.6586 7.57613 14.4243C7.34181 14.19 7.34181 13.8101 7.57613 13.5758L10.1519 11L7.57613 8.42429C7.34181 8.18997 7.34181 7.81007 7.57613 7.57576Z' fill='%234F4F4F'/%3E%3C/svg%3E%0A");
  }
  &:checked {
    &:before {
      content: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='21' height='21' rx='10.5' fill='transparent'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.8588 8.44038C15.1127 8.69422 15.1127 9.10578 14.8588 9.35962L9.65884 14.5596C9.405 14.8135 8.99344 14.8135 8.7396 14.5596L6.1396 11.9596C5.88576 11.7058 5.88576 11.2942 6.1396 11.0404C6.39344 10.7865 6.805 10.7865 7.05884 11.0404L9.19922 13.1808L13.9396 8.44038C14.1934 8.18654 14.605 8.18654 14.8588 8.44038Z' fill='%23CC3B33'/%3E%3C/svg%3E%0A");
    }
  }
}

.multiselect-checkbox:checked {
  @apply bg-green-medium;
}

.multiselect-checkbox:focus {
  @apply ring-2 ring-green-medium;
}

.multiselect-checkbox:active {
  @apply bg-green-medium text-green-medium;
}

[type='checkbox'] {
  @apply text-green-medium;
}
